import { Module } from 'tabulator-tables';

class SelectCell extends Module {
   constructor(table) {
      super(table);

      this.lastClickedCell = false; //last clicked cell
      this.selectedCells = []; //hold selected rows

      // register table options
      this.registerTableOption("selectableCells", "highlight");

      // register column options, selectable default to true
      this.registerColumnOption("selectableCellsInColumn", true);

      // register table functions
      this.registerTableFunction("selectCell", this.selectCells.bind(this));
      this.registerTableFunction("deselectCell", this.deselectCells.bind(this));
      this.registerTableFunction("toggleSelectCell", this.toggleCell.bind(this));
      this.registerTableFunction("getSelectedCells", this.getSelectedCells.bind(this));
      this.registerTableFunction("getSelectedCellsData", this.getSelectedCellsData.bind(this));

      //register component functions
      this.registerComponentFunction("cell", "select", this.selectCells.bind(this));
      this.registerComponentFunction("cell", "deselect", this.deselectCells.bind(this));
      this.registerComponentFunction("cell", "toggleSelect", this.toggleCell.bind(this));
      this.registerComponentFunction("cell", "isSelected", this.isCellSelected.bind(this));
   }

   initialize() {
      if (this.table.options.selectableCells !== false) {
         this.subscribe("cell-init", this.initializeCell.bind(this));
         this.subscribe("cell-rendered", this.renderCell.bind(this));
      }
   }

   initializeCell(cell) {
      var self = this,
         element = cell.getElement();

      if (self.checkCellSelectability(cell)) {
         element.classList.add("tabulator-selectable");
         element.classList.remove("tabulator-unselectable");

         if (self.table.options.selectableCells && self.table.options.selectableCells != "highlight") {
            element.addEventListener("click", this.handleComplexCellClick.bind(this, cell));
         }
      }

      cell.modules.select = { selected: false };
   }

   renderCell(cell) {
      let element = cell.getElement();

      if (element) {
         const selected = this.selectedCells.find(c => c.column == cell.column && c.row == cell.row);
         if (selected) {
            element.classList.add('tabulator-selected');

         } else {
            element.classList.remove('tabulator-selected');
         }
      }
   }

   handleComplexCellClick(cell, e) {

      if (e.shiftKey) {
         this.table._clearSelection();
         this.lastClickedCell = this.lastClickedCell || cell;

         // Rows
         var rowIdx = this.table.rowManager.getDisplayRowIndex(cell.row);
         var lastRowIdx = this.table.rowManager.getDisplayRowIndex(this.lastClickedCell.row);

         // Cells
         var cellIdx = cell.getIndex();
         var lastCellIdx = this.lastClickedCell.getIndex();

         if (e.ctrlKey || e.metaKey) {
            if (cellIdx == 0) {
               // Seleziona riga se click sulla prima colonna
               cellIdx = cell.row.getCells().length - 1;
               lastCellIdx = 0;
            } else if (rowIdx == 0) {
               // Seleziona colonna se click sulla prima riga
               rowIdx = this.table.rowManager.getRows().length - 1;
               lastRowIdx = 0;

            }
         }

         // Calcolo Range
         var fromRowIdx = lastRowIdx <= rowIdx ? lastRowIdx : rowIdx;
         var toRowIdx = lastRowIdx >= rowIdx ? lastRowIdx : rowIdx;
         var rows = this.table.rowManager.getDisplayRows().slice(0);
         var toggledRows = rows.splice(fromRowIdx, toRowIdx - fromRowIdx + 1);

         var fromCellIdx = lastCellIdx <= cellIdx ? lastCellIdx : cellIdx;
         var toCellIdx = lastCellIdx >= cellIdx ? lastCellIdx : cellIdx;
         var cells = [].concat(...toggledRows.map(row => row.getCells()));
         var toggledCells = cells.filter(cell => cell.getIndex() >= fromCellIdx && cell.getIndex() <= toCellIdx);

         // console.log(`[handleComplexCellClick], da ${fromCellIdx}:${fromRowIdx} a ${toCellIdx}:${toRowIdx} (${toggledCells.length})`);

         if ((e.ctrlKey || e.metaKey)) {
            const lastCellInRowSelected = cell.row.getCells()[cellIdx].modules.select.selected;

            if (cell.modules.select.selected || (lastCellInRowSelected && !cell.column.definition.selectableCellsInColumn)) {
               this.deselectCells(toggledCells);

            } else {
               this.selectCells(toggledCells);

            }

            this.lastClickedCell = false;


         } else {
            this.deselectCells(undefined, true);
            this.selectCells(toggledCells);
         }

         this.table._clearSelection();

      } else if (e.ctrlKey || e.metaKey) {
         this.toggleCell(cell);
         this.lastClickedCell = cell;

      } else {
         this.deselectCells(undefined, true);
         this.selectCells(cell);
         this.lastClickedCell = cell;

      }
   }

   checkCellSelectability(cell) {
      return true;
   }

   toggleCell(cell) {
      if (this.checkCellSelectability(cell)) {
         if (cell.modules.select && cell.modules.select.selected) {
            this._deselectCell(cell);
         } else {
            this._selectCell(cell);
         }
      }
   }

   _selectCell(cell, silent, force) {
      const self = this;
      const element = cell.getElement();
      let index;

      if (cell && cell.column.definition.selectableCellsInColumn) {
         index = self.selectedCells.findIndex(function (selectedCell) {
            return selectedCell == cell;
         });

         if (index == -1) {
            element.classList.add("tabulator-selected");
            self.selectedCells.push(cell);

            if (!cell.modules.select) {
               cell.modules.select = {};
            }
            cell.modules.select.selected = true;
         }
      }
   }

   _deselectCell(cell, silent, force) {
      const self = this;
      let index;

      if (cell) {
         index = self.selectedCells.findIndex(function (selectedCell) {
            return selectedCell == cell;
         });

         if (index > -1) {
            const element = cell.getElement();
            if (element) element.classList.remove("tabulator-selected");

            self.selectedCells.splice(index, 1);

            if (!cell.modules.select) {
               cell.modules.select = {};
            }
            cell.modules.select.selected = false;
         }
      }
   }

   deselectCells(cells, silent) {
      var self = this,
         cellCount;

      if (typeof cells == "undefined") {

         cellCount = self.selectedCells.length;

         for (let i = 0; i < cellCount; i++) {
            self._deselectCell(self.selectedCells[0], true);
         }

      } else {
         if (Array.isArray(cells)) {
            cells.forEach(function (cell) {
               self._deselectCell(cell, true);
            });

         } else {
            self._deselectCell(cells, silent);
         }
      }
   }

   selectCells(cells) {
      let cellMatch;

      switch (typeof cells) {
         case "undefined":
            cellMatch = 'todo';
            break;

         case "string":
            cellMatch = 'todo';
            break;

         default:
            if (Array.isArray(cells)) {
               cells.forEach((cell) => {
                  this._selectCell(cell, true, true);
               });

            } else {
               this._selectCell(cells, false, true);
            }
            break;
      }
   }

   getSelectedCellsData() {
      var data = [];

      this.selectedCells.forEach(function (cell) {
         data.push(cell.getValue());
      });

      return data;
   }

   getSelectedCells() {
      var cells = [];

      this.selectedCells.forEach(function (cell) {
         cells.push(cell.getComponent());
      });

      return cells;
   }

   isCellSelected(cell) {
      console.log("isCellSelected", this.selectedCells.indexOf(cell), cell, this.selectedCells);
      return this.selectedCells.indexOf(cell) !== -1;
   }

}

SelectCell.moduleName = "selectCell";
export default SelectCell;
