import { Module } from 'tabulator-tables';

class CustomFilters extends Module {
   constructor(table) {
      super(table);

      this.customFilters = [];

      // register table options
      this.registerTableOption("presets", false);

      // register table functions
      this.registerTableFunction("findCustomFilterIndex", this.findCustomFilterIndex.bind(this));
      this.registerTableFunction("getCustomFilters", this.getCustomFilters.bind(this));
      this.registerTableFunction("remCustomFilter", this.remCustomFilter.bind(this));
      this.registerTableFunction("addCustomFilter", this.addCustomFilter.bind(this));
      this.registerTableFunction("executeCustomFilters", this.executeCustomFilters.bind(this));
      this.registerTableFunction("getActualPreset", this.getActualPreset.bind(this));
   }

   initialize() {

      // this.subscribe("table-built", this.initializeCustomFilters.bind(this));
      this.subscribe("filter-changed", this.syncFilters.bind(this));

      if (this.options('presets')) {
         this.presets = this.options('presets');
         this.presetsMenu = null;
         this.totalQueries = [];
         this.renderPresetsMenu();

         if (this.options('data')) this.subscribe("data-refreshed", this.promiseDebounce(this.updatePresetsMenu.bind(this), 200));
         else this.subscribe("data-refreshed", this.updatePresetsMenu.bind(this));
      }
   }

   renderPresetsMenu() {
      const self = this;
      const presets = this.presets;
      const tableElement = this.table.element;
      let tableContainer = tableElement.closest('.tabulator-container');

      // Se non è in un container lo creo
      if (!tableContainer) {
         tableContainer = document.createElement('div');
         tableContainer.className = 'tabulator-container';
         tableElement.parentElement.insertBefore(tableContainer, tableElement);
         tableContainer.appendChild(tableElement);
      }

      // Se dentro un contenitore full-height la imposto a full-height
      if (tableElement.closest('.full-height-container')) {
         tableContainer.classList.add('full-height');
      }

      const presetsMenu = document.createElement('div');
      presetsMenu.classList.add('ui', 'small', 'menu', 'tabulator-presets-menu');
      tableContainer.parentElement.insertBefore(presetsMenu, tableContainer);

      const rightMenu = document.createElement('div');
      rightMenu.classList.add('right', 'menu');
      presetsMenu.appendChild(rightMenu);

      addPresets(presets, presetsMenu);

      this.presetsMenu = presetsMenu;
      $(presetsMenu).find('.ui.dropdown').dropdown();

      function addPresets(presetsArr, container) {
         presetsArr.forEach(preset => {
            const { label, icon, right, total, sub, sort, filters } = preset;

            if (right) {
               container = rightMenu;
            }

            let element = null;
            if (sub) {
               element = document.createElement('div');
               element.classList.add('ui', 'vertically', 'fitted', 'dropdown', 'item');
               element.innerHTML = `
                     <i class="${icon} icon"></i>${label}&nbsp;
                     <span class="text">...</span>
                     <i class="dropdown icon"></i>
                     <div class="menu"></div>
                  `;

               const subMenu = element.querySelector('.menu');
               addPresets(sub, subMenu);

            } else if (filters) {
               element = document.createElement('a');
               element.classList.add('vertically', 'fitted', 'item');
               element.dataset.presetname = label;
               element.innerHTML = `
                     ${icon ? `<i class="${icon} icon"></i>` : ''}
                     ${label}
                     ${total ? `<div class="left aligned floating ui small label preset-total">-</div>` : ''}
                  `;

               element.addEventListener('mouseup', (ev) => {
                  self.setPresetFilter(preset.filters);
               });
            }

            if (right) {
               rightMenu.appendChild(element);

            } else if (container == presetsMenu) {
               container.insertBefore(element, rightMenu);

            } else {
               container.appendChild(element);
            }
         })

      }
   }

   updatePresetsMenu(filter) {
      if (!this.presets) return;

      console.log('data-refreshed')

      const presetsMenu = table.modules.customFilters.presetsMenu;
      const actualPreset = this.getActualPreset();

      // Reset
      presetsMenu.querySelectorAll('.active-preset').forEach(ap => ap.classList.remove('active-preset'));
      presetsMenu.querySelectorAll('.ui.dropdown').forEach(drop => {
         drop.classList.remove('active-preset');
      });

      // Set
      if (actualPreset) {
         const actualElement = presetsMenu.querySelector(`[data-presetname="${actualPreset.label}"]`);
         const actualElementDrop = actualElement.closest('.ui.dropdown');

         actualElement.classList.add('active-preset');
         if (actualElementDrop) actualElementDrop.classList.add('active-preset');
      }

      this.updatePresetsTotals();
   }

   updatePresetsTotals() {
      if (!this.presets) return;

      const presets = this.presets;
      const customFilters = [...this.getCustomFilters()];
      const newTotalQueries = presets.map(preset => {
         const { total, filters } = preset;
         if (!total) return [];

         const fields = filters.map(f => f.filter.field).filter(Boolean);
         const totalQuery = filters.map(({ filter }) => filter);

         customFilters.forEach(({ filter }) => {
            if (Array.isArray(filter) || !fields.includes(filter.field)) {
               totalQuery.push(filter);
            }
         });

         preset.totalQuery = totalQuery;
         return totalQuery;
      });

      // Don't update if the queries are equal
      if (JSON.stringify(this.totalQueries) === JSON.stringify(newTotalQueries)) {
         return;
      }

      this.totalQueries = newTotalQueries;

      presets.forEach(preset => {
         const { label, totalQuery } = preset;
         const labelElement = this.presetsMenu.querySelector(
            `[data-presetname="${label}"] .preset-total`
         );

         if (!totalQuery || !labelElement) {
            return;
         }

         labelElement.classList.remove('blue');
         labelElement.innerHTML = `<i class="fitted asterisk loading icon"></i>`;

         if (this.options('featherService')) {
            const url = this.table.modules.featherService.featherService;
            const query = this.table.modules.featherService.feathersQueryGenerator({
               filter: totalQuery
            });

            delete query.$sort;
            query.$limit = 0;

            client.service(url)
               .find({ query })
               .then(({ total }) => {
                  if (total) {
                     labelElement.classList.add('blue');
                  }
                  labelElement.innerHTML = new Intl.NumberFormat('it-IT').format(total);
               });
         } else {
            const total = this.table.modules.filter.searchData(totalQuery).length;
            if (total) {
               labelElement.classList.add('blue');
            }
            labelElement.innerHTML = new Intl.NumberFormat('it-IT').format(total);

         }
      });
   }

   setPresetFilter(filter) {
      this.addCustomFilter(filter, true, 'preset');
   }

   findCustomFilterIndex(filter) {
      const customFilters = this.getCustomFilters();
      return customFilters.findIndex(cf => JSON.stringify(cf) === JSON.stringify(filter));
   }

   syncFilters() {
      const table = this.table;
      const tableFilters = table.modules.filter.getFilters();
      const headerFilters = table.modules.filter.getHeaderFilters();

      const customFilters = [...this.getCustomFilters()]
         .filter(cf => cf.custom !== 'header' && cf.custom !== 'table');

      // Sync Header Filters
      headerFilters.forEach(hf => {
         const toAdd = { filter: hf, custom: 'header' }
         const exist = customFilters.find(cf => JSON.stringify(cf) === JSON.stringify(toAdd));
         if (hf.value && !exist) customFilters.push({ filter: hf, custom: 'header' });
      });

      // Sync table Filters
      tableFilters.forEach(tf => {
         const exist = customFilters.map(cf => cf.filter).find(cf => JSON.stringify(cf) === JSON.stringify(tf));
         if(!exist) customFilters.push({ filter: tf, custom: 'table' });
      });

      if (this.hasCustomFiltersChanged(customFilters)) {
         this.customFilters = customFilters;
      }
   }

   getCustomFilters() {
      return this.customFilters;
   }

   addCustomFilter(filter, commit, remove) {
      if (remove) {
         this.remCustomFilter(typeof remove === 'string' ? remove : 'all');
      }

      let newFilters = [...this.getCustomFilters()];

      if (!Array.isArray(filter)) {
         filter = [filter];
      }

      filter.forEach(fi => {
         if (!fi.custom) {
            fi.custom = 'table';
         }

         const index = this.findCustomFilterIndex(fi);
         if (index < 0 && fi.filter.value) {
            newFilters.push(fi);
         }
      });

      if (commit !== false) {
         this.executeCustomFilters(newFilters);
      }

      this.customFilters = newFilters;
   }

   remCustomFilter(filter, commit) {
      let customFilters = [...this.getCustomFilters()];

      if (filter === 'all') {
         customFilters.length = 0;

      } else if (typeof filter === 'string') {
         customFilters = customFilters.filter(cf => cf.custom !== filter);

      } else {
         if (!Array.isArray(filter)) filter = [filter];

         const indexesToRemove = filter
            .map(fi => this.findCustomFilterIndex(fi))
            .filter(i => i > -1)
            .sort((a, b) => b - a);

         for (const index of indexesToRemove) {
            customFilters.splice(index, 1);
         }

      }

      if (commit !== false) {
         this.executeCustomFilters(customFilters);
      }

      this.customFilters = customFilters;
   }

   executeCustomFilters(newFilters) {
      const table = this.table;
      const customFilters = newFilters || this.getCustomFilters();

      if (!this.hasCustomFiltersChanged(customFilters)) {
         return;
      }

      const filtersWithValues = customFilters.filter(filObj => filObj.filter.value || Array.isArray(filObj.filter));
      const headerFilters = filtersWithValues.filter(filObj => filObj.custom === 'header');

      // Set table filters
      table.clearFilter(true);
      table.setFilter(filtersWithValues.filter(filter => filter.custom !== 'header').map(filter => filter.filter));

      // Set header filters
      headerFilters.forEach(filter => {
         const { field, value } = filter.filter;
         table.setHeaderFilterValue(field, value);
      });

      this.customFilters = filtersWithValues;
   }

   hasCustomFiltersChanged(newFilters) {
      const oldFilters = this.getCustomFilters();
      if (oldFilters.length !== newFilters.length) {
         return true;
      }

      let found = 0;
      newFilters.forEach((newFilter) => {
         const index = oldFilters.findIndex(
            (oldFilter) => JSON.stringify(oldFilter) === JSON.stringify(newFilter)
         );
         if (index > -1) {
            found++;
         }
      });

      return found !== oldFilters.length;
   }

   getActualPreset() {
      if (!this.presets) return null;

      const presets = [].concat(...this.presets.map(p => p.sub ? p.sub : p)).sort((a, b) => b.filters.length - a.filters.length);
      const customFilters = this.getCustomFilters();

      let actualPreset = null;
      presets.forEach(preset => {
         let found = 0;
         preset.filters.forEach(filter => {
            if (customFilters.find(cf => JSON.stringify(cf) === JSON.stringify(filter))) {
               found++;
            };
         });

         if (found === preset.filters.length) {
            actualPreset = preset;
         }
      });

      return actualPreset;
   }

   promiseDebounce(func, wait) {
      let timeout;
      return function (...args) {
         // console.log('---------------------------------------- Debouncing...')
         clearTimeout(timeout);
         return new Promise((resolve) => {
            timeout = setTimeout(() => {
               // console.log('---------------------------------------- Go...')
               resolve(func.apply(this, args));
            }, wait);
         });
      };
   }
}

CustomFilters.moduleName = "customFilters";
export default CustomFilters;