import { Module } from 'tabulator-tables';

class ExtraControls extends Module {
   constructor(table) {
      super(table);

      this.tableContainer = null;
      this.extraControls = null;
      this.leftControls = null;
      this.rightControls = null;

      // register table options
      this.registerTableOption("extraControls", false);

   }

   initialize() {
      if (this.table.options.extraControls !== false) {
         this.subscribe("table-built", this.initializeExtraControls.bind(this));

         if (this.options('data'))
            this.subscribe("data-refreshed", this.promiseDebounce(this.updateExtraControls.bind(this), 200));
         else
            this.subscribe("data-refreshed", this.updateExtraControls.bind(this));
      }
   }

   initializeExtraControls() {
      console.log("initializeExtraControls", this);
      const tableElement = this.table.element;
      let tableContainer = tableElement.closest('.tabulator-container');

      // Se non è in un container lo creo
      if(!tableContainer) {
         tableContainer = document.createElement('div');
         tableContainer.className = 'tabulator-container';
         tableElement.parentElement.insertBefore(tableContainer, tableElement);
         tableContainer.appendChild(tableElement);
      }

      // Se dentro un contenitore full-height la imposto a full-height
      if (tableElement.closest('.full-height-container')) {
         tableContainer.classList.add('full-height');
      }

      const extraControls = document.createElement('div');
      extraControls.className = 'extra-controls';
      tableContainer.parentElement.insertBefore(extraControls, tableContainer);

      const leftControls = document.createElement('div');
      leftControls.classList.add('left-controls', 'ui', 'labels');
      extraControls.appendChild(leftControls);

      const rightControls = document.createElement('div');
      rightControls.classList.add('right-controls', 'ui', 'basic', 'labels');
      extraControls.appendChild(rightControls);

      const copySelectedRowsLabel = document.createElement('a');
      copySelectedRowsLabel.classList.add('copySelectedRowsLabel', 'ui', 'label');
      copySelectedRowsLabel.innerHTML = '<i class="copy icon"></i>Copia';
      rightControls.appendChild(copySelectedRowsLabel);

      const exportExcelLabel = document.createElement('a');
      exportExcelLabel.classList.add('exportExcelLabel', 'ui', 'label');
      exportExcelLabel.innerHTML = '<i class="file excel icon"></i>Esporta';
      rightControls.appendChild(exportExcelLabel);

      this.tableContainer = tableContainer;
      this.extraControls = extraControls;
      this.leftControls = leftControls;
      this.rightControls = rightControls;
   }

   async updateExtraControls() {
      console.log('update extra controls')
      if (!this.leftControls) return;

      const self = this;
      const leftControls = this.leftControls;
      const featherService = this.table.options.featherService;

      ////////////////////////////////////////////// Reset
      leftControls.innerHTML = '';

      ////////////////////////////////////////////// Clear Button
      const resetFiltersLabel = document.createElement('a');
      resetFiltersLabel.classList.add('resetFiltersLabel', 'ui', 'red', 'label');
      resetFiltersLabel.innerHTML = '<i class="fitted alternate trash icon"></i>';
      leftControls.appendChild(resetFiltersLabel);

      resetFiltersLabel.addEventListener('mouseup', (e) => {
         self.table.clearSort();
         self.table.clearFilter(true);
      });

      ////////////////////////////////////////////// Total
      const totalRows = featherService ? await this.table.getFeathersTotalRows()
         : this.table.getDataCount();
      const filteredRows = featherService ? await this.table.getFeathersFilteredRows()
         : this.table.getRows("active").length;

      const totalFilteredLabel = document.createElement('div');
      totalFilteredLabel.classList.add('totalFilteredLabel', 'ui', 'label');
      totalFilteredLabel.innerHTML = `
            ${new Intl.NumberFormat('it-IT').format(filteredRows)} di
            ${new Intl.NumberFormat('it-IT').format(totalRows)}
         `;
      leftControls.appendChild(totalFilteredLabel);

      ////////////////////////////////////////////// Order
      const order = this.table.modules.sort.getSort();
      order.forEach(ord => {
         const orderLabel = document.createElement('div');
         const txt = ord.column.getDefinition().title;
         const dir = ord.dir == 'asc' ? 'up' : 'down';
         orderLabel.classList.add('orderLabel', 'ui', 'label');
         orderLabel.innerHTML = `<i class="fitted sort ${dir} icon"></i> ${txt}`;
         leftControls.appendChild(orderLabel);
      });

      ////////////////////////////////////////////// Filters
      const multiSearchFields = self.options('multiSearch') ? self.options('multiSearch').fields : [];
      let filters;

      if (this.table.modules.customFilters) {
         filters = this.table.modules.customFilters.getCustomFilters();

      } else {
         filters = this.table.getFilters()
            .map(f => ({ filter: f, custom: 'table' }))
            .concat(
               this.table.getHeaderFilters()
                  .map(f => ({ filter: f, custom: 'header' }))
         );
      }

      filters.forEach(filObj => {
         const { filter, custom } = filObj;
         const isMainSearch = Array.isArray(filter) && JSON.stringify(multiSearchFields) == JSON.stringify(filter.map(fi => fi.field));
         let html, classes, handleClick;

         // console.log('------------------print', filter, custom)

         if (isMainSearch) {
            html = `<span class="opacita6"><i class="search icon"></i>Search:</span> ${filter[0].value}`;
            classes = ['filterLabel', 'ui', 'green', 'label'];
            handleClick = () => {
               self.table.removeFilter([filter]);
               self.table.modules.multiSearch.element.value = '';
            };

         } else if (custom == 'table' || custom == 'preset') {
            const filterArr = Array.isArray(filter) ? filter : [filter];
            html = filterArr.map(fi => {
               const { field, type, value } = fi;
               const col = self.table.getColumn(field);

               return `
                  <span class="opacita6">
                     <i class="filter icon"></i>${col ? col.getDefinition().title : field}
                     ${type.toUpperCase()}
                  </span> ${value}`
            }).join(' OR ');
            classes = ['filterLabel', 'ui', 'basic', 'label'];
            handleClick = () => custom == 'preset' ? self.table.remCustomFilter(filObj) : self.table.removeFilter([filter]) ;

         } else if (custom == 'header') {
            const filterArr = Array.isArray(filter) ? filter : [filter];
            html = filterArr.map(fi => {
               const { field, type, value } = fi;
               const col = self.table.getColumn(field);

               return `
                  <span class="opacita6">
                     <i class="filter icon"></i>${col ? col.getDefinition().title : field}
                     ${type.toUpperCase()}
                  </span> ${value}`
            }).join(' OR ');
            classes = ['filterLabel', 'ui', 'basic', 'label'];
            handleClick = () => self.table.setHeaderFilterValue(filter.field, '');

         }

         if (html) {
            const filterLabel = document.createElement('a');
            filterLabel.classList.add(...classes);
            filterLabel.innerHTML = html;
            filterLabel.addEventListener('click', handleClick);
            leftControls.appendChild(filterLabel);
         }
      });
   }

   promiseDebounce(func, wait) {
      let timeout;
      return function (...args) {
         // console.log('---------------------------------------- Debouncing...')
         clearTimeout(timeout);
         return new Promise((resolve) => {
            timeout = setTimeout(() => {
               // console.log('---------------------------------------- Go...')
               resolve(func.apply(this, args));
            }, wait);
         });
      };
   }
}

ExtraControls.moduleName = "extraControls";
export default ExtraControls;