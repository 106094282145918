import { Module } from 'tabulator-tables';

class MultiSearch extends Module {
   constructor(table) {
      super(table);

      this.MultiSearch = null;


      // register table options
      this.registerTableOption("multiSearch", false);

      // register table functions
      // this.registerTableFunction("getFeathersTotalRows", this.getFeathersTotalRows.bind(this));
      // this.registerTableFunction("getFeathersFilteredRows", this.getFeathersFilteredRows.bind(this));
   }

   initialize() {
      if (this.options('multiSearch') && this.options('multiSearch').selector) {

         this.selector = this.options('multiSearch').selector;
         this.fields = this.options('multiSearch').fields;

         this.subscribe("table-built", this.initializeMultiSearch.bind(this));
      }
   }

   initializeMultiSearch() {
      const self = this;
      const element = document.querySelector(this.selector);
      const fields = this.fields;
      if (!element) return;

      this.element = element;

      console.log('initializeMultiSearch', this, fields);

      element.classList.add('multi-search');
      element.title = `Cerca nei campi: ${fields.join(', ')}`
         + `\nVirgolette per cercare il testo esatto, es: "Tron"`
         + `\nAsterischi come wildcards, es: Superm*n`
         + `\n(Rimuove tutti gli altri filtri)`;

      element.addEventListener('keyup', (ev) => {
         const value = ev.target.value;
         search(value);
      });

      function search(value) {
         value = value.trim();
         const newFilter = fields.map((field) => ({ field: field, type: '=*', value: value, custom: 'search' }));
         if (self.table.remCustomFilter) self.table.remCustomFilter('all');
         self.table.setFilter([newFilter]);
      }
   }
}

MultiSearch.moduleName = "multiSearch";
export default MultiSearch;