import { Module } from 'tabulator-tables';

class FeatherService extends Module {
   constructor(table) {
      super(table);

      this.featherService = null;
      this.pagination = null;
      this.lastQuery = null;
      this.totalRows = 0;
      this.filteredRows = 0;

      // register table options
      this.registerTableOption("featherService", false);

      // register table functions
      this.registerTableFunction("getFeathersTotalRows", this.getFeathersTotalRows.bind(this));
      this.registerTableFunction("getFeathersFilteredRows", this.getFeathersFilteredRows.bind(this));
   }

   initialize() {
      if (this.options('featherService')) {

         this.featherService = this.options('featherService');
         this.pagination = this.options('pagination');

         this.subscribe("data-loading", this.requestDataCheck.bind(this));
         this.subscribe("data-load", this.promiseDebounce(this.featherRequestFunc.bind(this), 400));
      }
   }

   requestDataCheck(data, params, config, silent) {
      return !!(this.featherService);
   }

   promiseDebounce(func, wait) {
      let timeout;
      return function (...args) {
         // console.log('---------------------------------------- Debouncing...')
         clearTimeout(timeout);
         return new Promise((resolve) => {
            timeout = setTimeout(() => {
               // console.log('---------------------------------------- Go...')
               resolve(func.apply(this, args));
            }, wait);
         });
      };
   }

   featherRequestFunc(data, params) {
      console.log('[featherRequestFunc]', data, params);
      const url = this.featherService;
      const query = this.feathersQueryGenerator(params);

      console.time(`[${url}]`);
      console.log(`[${url}]:`, { query });

      return client.service(url).find({ query: query })
         .then(res => {
            this.filteredRows = res.total;

            if (this.pagination) {
               const lastPage = Math.ceil(res.total / params.size);
               res = {
                  last_page: lastPage,
                  last_row: res.total,
                  data: res.data
               };
            } else {
               res = res.data;
            }

            console.timeEnd(`[${url}]`);
            console.log(`[${url}]:`, res);
            return res;
         });
   }

   feathersQueryGenerator(params) {

      const sort = params.sort || [];
      const page = params.page || 1;
      const size = params.size || 25;
      const filter = params.filter || [];
      const select = params.select || [];
      const query = {};

      // SELECT
      if (select.length)
         query.$select = select;

      // PAGINATION
      query.$limit = size;
      if (page > 1)
         query.$skip = (page - 1) * size;

      // SORT
      if (!sort.length) sort.push({ field: 'id', dir: 'asc' });
      query.$sort = {};
      sort.forEach(s => query.$sort[s.field] = s.dir == 'asc' ? 1 : -1);

      // FILTERS
      const alreadyFiltered = [];
      filter.forEach(f => {
         if (!query.$and) query.$and = [];

         if (f.field && !alreadyFiltered.includes(f.field)) {
            query.$and.push(getFilter(f));
            alreadyFiltered.push(f.field);

         } else if (Array.isArray(f)) {
            query.$and.push(getFilter(f));

         }
      })

      function getFilter(filter) {
         const { field, type, value } = filter;
         let filO = {};

         if (Array.isArray(filter)) {
            filO.$or = filter.map((filter) => getFilter(filter));
         }

         // TYPE AND VALUE
         if (type === '=') {
            filO[field] = value;

         } else if (type === '=*') {
            if (/^\".*\"$/gi.test(value)) {
               filO[field] = value.replace(/^\"|\"$/gi, '');

            } else if (/\*/gi.test(value)) {
               filO[field] = { $like: value.replace(/\*/gi, '%') };

            } else {
               filO[field] = { $like: `%${value}%` };

            }
         } else if (type === '!=') {
            filO[field] = { $ne: value };

         } else if (type === 'in') {
            filO[field] = { $in: value };

         } else if (type === '<') {
            filO[field] = { $lt: value };

         } else if (type === '<=') {
            filO[field] = { $lte: value };

         } else if (type === '>') {
            filO[field] = { $gt: value };

         } else if (type === '>=') {
            filO[field] = { $ge: value };

         }

         // console.log('[filter Object]\t', filO);
         return filO;
      }

      this.lastQuery = query;
      return query;
   }

   async getFeathersTotalRows() {
      const url = this.featherService;
      const totalRows = await client.service(url).find({ query: { $limit: 0 } }).then(res => res.total);
      return totalRows;
   }

   async getFeathersFilteredRows() {
      return this.filteredRows;
   }

}

FeatherService.moduleName = "featherService";
export default FeatherService;