import { TabulatorFull as Tabulator } from 'tabulator-tables';

Tabulator.extendModule("sort", "sorters", {
   excelRowNumbersSorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
      const aN = aRow.getData()[0].a.replace(/[^0-9]/gi, '');
      const bN = bRow.getData()[0].a.replace(/[^0-9]/gi, '');
      return parseInt(aN) - parseInt(bN);
   },
   excelSorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
      return (a.txt ? a.txt : '').localeCompare(b.txt ? b.txt : '')
   },
});