import { TabulatorFull as Tabulator } from 'tabulator-tables';

Tabulator.extendModule("filter", "filters", {
   "=*": function (headerValue, rowValue, rowData, filterParams) {
      let cleanValue = headerValue.trim();
      let isRegExp = false;

      if (/^\".*\"$/gi.test(cleanValue)) {
         cleanValue = cleanValue.replace(/^\"(.*)\"$/gi, '$1');
      } else if (/\*/gi.test(cleanValue)) {
         cleanValue = cleanValue.replace(/\*/gi, '[*]');
         isRegExp = true;
      } else {
         cleanValue = `[*]${cleanValue}[*]`;
         isRegExp = true;
      }



      if (isRegExp) {
         cleanValue = '^' + cleanValue.split('[*]').map(s => myFunc.escapeRegex(s)).join('.*') + '$';
         return new RegExp(cleanValue, 'gi').test(rowValue);
      } else {
         return rowValue.toLowerCase() === cleanValue.toLowerCase();
      }
   },
   "=*Excel": function (headerValue, rowValue, rowData, filterParams) {
      let cleanValue = headerValue.trim();
      let isRegExp = false;

      if (/^\".*\"$/gi.test(cleanValue)) {
         cleanValue = cleanValue.replace(/^\"(.*)\"$/gi, '$1');
      } else if (/\*/gi.test(cleanValue)) {
         cleanValue = cleanValue.replace(/\*/gi, '[*]');
         isRegExp = true;
      } else {
         cleanValue = `[*]${cleanValue}[*]`;
         isRegExp = true;
      }

      if (isRegExp) {
         cleanValue = '^' + cleanValue.split('[*]').map(s => myFunc.escapeRegex(s)).join('.*') + '$';
         return new RegExp(cleanValue, 'gi').test(rowValue.txt);
      } else {
         return rowValue.txt.toLowerCase() === cleanValue.toLowerCase();
      }
   }
});