// Globals
const {mySite, authentication} = require( '../config/default.json' );
global.mySite = mySite;
global.myExpiration = authentication.jwtOptions.expiresIn;

// FEATHERS CLIENT
import "@babel/polyfill";
import io from 'socket.io-client';
import feathers from '@feathersjs/client';

global.socket = io(mySite);
global.client = feathers();
client.configure( feathers.authentication( { storage: window.localStorage } ) );
client.configure(feathers.socketio(socket, {
   timeout: 15000
} )  );

// JQUERY
global.$ = global.jQuery = require('jquery');

// MOMENT
global.moment = require('moment');
global.moment.locale('it');

// LODASH
const _ = require('lodash');

// FUSE
global.Fuse = require('fuse.js/dist/fuse.js');

// DIFF
global.Diff = require('diff');

// CHART
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
global.Chart = Chart;

// SELECTION
import SelectionArea from '@viselect/vanilla/lib/viselect.esm.js';
global.SelectionArea = SelectionArea;

// DATATABLE
import dt from 'datatables.net';

// DATATABLE PLUGINS
import 'datatables.net-select-se';
import 'datatables.net-rowreorder-se';
import "datatables.net-buttons-se";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-fixedheader-se/js/fixedHeader.semanticui.js";
import "datatables.net-fixedcolumns-se/js/fixedColumns.semanticui.js";
import "datatables.net-scroller-se/js/scroller.semanticui.js";

// TABULATOR CUSTOMS
import './dtv/myTabulator/formatters.js';
import './dtv/myTabulator/sorters.js';
import './dtv/myTabulator/filters.js';
import './dtv/myTabulator/editors.js';

// TABULATOR CUSTOM MODULES
import FeatherService from './dtv/myTabulator/modules/featherService.js';
import SelectCell from './dtv/myTabulator/modules/selectCell.js';
import ExtraControls from './dtv/myTabulator/modules/extraControls.js';
import MultiSearch from './dtv/myTabulator/modules/multiSearch.js';
import CustomFilters from './dtv/myTabulator/modules/customFilters.js';

// TABULATOR CSS
import './dtv/myTabulator/css/myTabulator.css';

import {
   Tabulator,
   FormatModule,
   FilterModule,
   EditModule,
   AjaxModule,
   ClipboardModule,
   ExportModule,
   FrozenColumnsModule,
   FrozenRowsModule,
   HistoryModule,
   InteractionModule,
   KeybindingsModule,
   MenuModule,
   MoveColumnsModule,
   MoveRowsModule,
   PageModule,
   PersistenceModule,
   PopupModule,
   PrintModule,
   ReactiveDataModule,
   ResizeColumnsModule,
   ResizeRowsModule,
   SelectRowModule,
   SortModule,
   TooltipModule,

} from 'tabulator-tables';

Tabulator.registerModule([
   FormatModule,
   FilterModule,
   EditModule,
   AjaxModule,
   ClipboardModule,
   ExportModule,
   FrozenColumnsModule,
   FrozenRowsModule,
   HistoryModule,
   InteractionModule,
   KeybindingsModule,
   MenuModule,
   MoveColumnsModule,
   MoveRowsModule,
   PageModule,
   PersistenceModule,
   PopupModule,
   PrintModule,
   ReactiveDataModule,
   ResizeColumnsModule,
   ResizeRowsModule,
   SelectRowModule,
   SortModule,
   TooltipModule,
   // Custom
   FeatherService,
   SelectCell,
   ExtraControls,
   MultiSearch,
   CustomFilters
]);

global.Tabulator = Tabulator;

// DATATABLE CSS
import 'datatables.net-se/css/dataTables.semanticui.css';

// XLS SHEET.JS
global.XLSX = require('@sheet/core/dist/xlsx.full.min.js');

// JSZIP
import { saveAs } from 'file-saver';
global.saveAs = saveAs;

import JSZip from 'jszip'
global.JSZip = JSZip;

// SEMANTIC
require('../node_modules/fomantic-ui/dist/semantic.min.js');
require('../node_modules/fomantic-ui/dist/semantic.min.css');