import { TabulatorFull as Tabulator } from 'tabulator-tables';

Tabulator.extendModule("edit", "editors", {
   excelEditor: function (cell, onRendered, success, cancel) {
      let valueObj = cell.getValue();
      const input = document.createElement("input");

      input.setAttribute("type", "text");
      input.style.padding = "4px";
      input.style.width = "100%";
      input.style.boxSizing = "border-box";
      input.value = valueObj.txt;

      onRendered(function () {
         input.focus();
         input.style.height = "100%";
      });

      function onChange() {
         if (input.value != valueObj.txt) {
            if (!valueObj) valueObj = {};
            valueObj.txt = input.value;
            valueObj.par = null;
            valueObj.fmt = null;
            valueObj.tip = null;
            valueObj.val = null;

            success(valueObj);

         } else {
            cancel();
         }
      }

      //submit new value on blur or change
      input.addEventListener("blur", onChange);
      //submit new value on enter
      input.addEventListener("keydown", function (e) {
         if (e.keyCode == 13) {
            onChange();
         }

         if (e.keyCode == 27) {
            cancel();
         }
      });

      return input;

   },
});