import { TabulatorFull as Tabulator } from 'tabulator-tables';

Tabulator.extendModule("format", "formatters", {
   selectIdHeader: function () {
      const html = `
            <div class="selectToIconHeader">
               <div class="selectToIconHeaderText"></div>
            </div>`;
      return html;
   },

   selectId: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const html = `
            <div class="selectToIcon">
               <div>${value}</div>
            </div>`;
      return html;
   },
   boolean: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      let html = '';

      if (value) {
         html = '<i class="fitted large grey check circle icon"></i>';
      } else {
         html = '<i class="fitted large grey ban icon"></i>';
      }

      return html;
   },
   user: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const user = formatterParams.elsArray.find(u => u.id == value);

      let html = `User: ${value}`;

      if (user) {
         const { avatar, nome, cognome, ruolo } = user;
         const avatarSrc = avatar ? `./avatars/${avatar}` : './avatars/user.png';
         const firstNameInitial = nome ? `${nome.substring(0, 1)}.` : '';
         const userHeader = `${firstNameInitial} ${cognome}`;

         html = `
               <div class="ui relaxed divided link list userList">
                  <a class="item">
                     <img class="ui avatar image" src="./${avatarSrc}">
                     <div class="content">
                        <div class="header">${userHeader}</div>
                        <div class="description">${ruolo}</div>
                     </div>
                  </a>
               </div>`;
      }

      return html;
   },
   userDate: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const user = formatterParams.elsArray.find(u => u.id == value);
      const row = cell.getRow().getData();

      let html = `User: ${value}`;

      if (user) {
         const { avatar, nome, cognome } = user;
         const avatarSrc = avatar ? `./avatars/${avatar}` : './avatars/user.png';
         const firstNameInitial = nome ? `${nome.substring(0, 1)}.` : '';
         const userHeader = `${firstNameInitial} ${cognome}`;

         const date = row[formatterParams.dateField];
         const formattedDate = date ? moment.utc(date).format('DD/MM/YY') : '???';

         html = `
               <div class="ui relaxed divided link list userList">
                  <a class="item">
                     <img class="ui avatar image" src="./${avatarSrc}">
                     <div class="content">
                        <div class="header">${userHeader}</div>
                        <div class="description">${formattedDate}</div>
                     </div>
                  </a>
               </div>`;
      }

      return html;
   },
   elementsList: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const parsedArray = myFunc.tryJsonParse(value);
      let html = parsedArray;

      if (Array.isArray(parsedArray)) {
         html = `
               <div class="cell-scroll">
                  <div class="">
                     ${parsedArray.map((el, i) => `
                        <div class="item">${el == '*' ? 'Tutti' : el}</div>
                     `).join('')}
                  </div>
               </div>`;

         if (formatterParams.elsArray) {
            const matchingEls = formatterParams.elsArray.filter(el =>
               parsedArray.map(a => parseInt(a)).includes(el.id)
            );

            html = `
                  <div class="cell-scroll">
                     <div class="">
                        ${matchingEls.map((el, i) => `
                           <div class="item">${el.Name ? el.Name : el.nome ? el.nome : '???'} (${el.id})</div>
                        `).join('')}
                     </div>
                  </div>`;
         }
      }

      return html;
   },
   rowTitle: function (cell, formatterParams, onRendered) {
      const row = cell.getRow().getData();
      if (!formatterParams)
         throw new Error('formatterParams mancanti per il formato colonna rowTitle');

      let line1 = row[formatterParams.line1] ? row[formatterParams.line1] : '-';
      let line2 = row[formatterParams.line2] ? row[formatterParams.line2] : '-';
      let line3 = row[formatterParams.line3] ? row[formatterParams.line3] : '-';

      let html = '';

      html += `<div><b>${line1}</b></div>`;
      if (line1 != line2 || line1 != line3 || line2 != line3) {
         html += `<div>${line2}</div>`;
         html += `<div class="opacita6">${line3}</div>`;
      }

      return html;
   },
   dataTvIds: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const row = cell.getRow().getData();
      let idSerie = row.idSerie;
      let idProgram = value;
      let idEpisode = row.idEpisode;

      if (formatterParams) {
         idSerie = row[formatterParams.idSerie];
         idProgram = row[formatterParams.idProgram];
         idEpisode = row[formatterParams.idEpisode];
      }

      let html = '';

      if (idSerie && idSerie != -1)
         html += `<div class="ui smallest orange label" title="ID Serie: ${idSerie}">SE ${idSerie}</div>`;
      if (idProgram && idProgram != -1)
         html += `<div class="ui smallest blue label" title="ID Programma: ${idProgram}">PR ${idProgram}</div>`;
      if (idEpisode && idEpisode != -1)
         html += `<div class="ui smallest teal label" title="ID Episodio: ${idEpisode}">EP ${idEpisode}</div>`;

      return html;
   },

   // Excel Editor
   excelFormatter: function (cell, formatterParams, onRendered) {
      const value = cell.getValue();
      const cellElement = cell.getElement();
      const cellColor = value.sty?.fgColor?.rgb;
      const fontColor = value.sty?.color?.rgb;
      const fontSize = value.sty?.sz;

      let html = '';

      if (cellColor) {
         cellElement.style.backgroundColor = '#' + cellColor;
      }

      if (fontColor) {
         cellElement.style.color = '#' + fontColor;
      }

      if (fontSize) {
         cellElement.style.fontSize = fontSize + 'px';
      }

      html = value.txt;
      return html;
   },
   excelEditor: function (cell, onRendered, success, cancel) {
      let valueObj = cell.getValue();
      const input = document.createElement("input");

      input.setAttribute("type", "text");
      input.style.padding = "4px";
      input.style.width = "100%";
      input.style.boxSizing = "border-box";
      input.value = valueObj.txt;

      onRendered(function () {
         input.focus();
         input.style.height = "100%";
      });

      function onChange() {
         if (input.value != valueObj.txt) {
            if (!valueObj) valueObj = {};
            valueObj.txt = input.value;
            valueObj.par = null;
            valueObj.fmt = null;
            valueObj.tip = null;
            valueObj.val = null;

            success(valueObj);

         } else {
            cancel();
         }
      }

      //submit new value on blur or change
      input.addEventListener("blur", onChange);
      //submit new value on enter
      input.addEventListener("keydown", function (e) {
         if (e.keyCode == 13) {
            onChange();
         }

         if (e.keyCode == 27) {
            cancel();
         }
      });

      return input;

   },
   excelRowNumbersFormatter: function (cell, formatterParams, onRendered) {
      const cellData = cell.getRow().getData()[0];
      return `<b>${cellData.a.replace(/[^0-9]/gi, '')}</b>`;
   },

   // Timone Generale
   tg_stato: function (cell, formatterParams, onRendered) {
      return 'tg_stato TODO'
   }
});